import { useState } from 'react';
import './App.css';
import axios from 'axios';

function App() {
  const [file, setFile] = useState('');
  const [data, setData] = useState({chunks: 0, price: 0, timeMin:0})
  const [progress, setProgess] = useState<string>();
  const [error, setError] = useState('')
  
  const handleChange = (e: any) => {
    setProgess('0%')
    const file = e.target.files[0];
    console.log(file);
    setFile(file);
  }

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file);

    axios.post('https://encodegpt-moremoney0404.b4a.run//upload', formData, {
      onUploadProgress: (ProgressEvent) => {
        let progress = Math.round(
          // @ts-ignore
          ProgressEvent.loaded / ProgressEvent.total * 100
        ) + '%';
        // console.log(ProgressEvent)
        setProgess(progress);
        setError('')
      }
    }).then(res => {
      console.log(res);
      setData(res.data)
    }).catch(err => {
      setError(err.response.data.msg)
      console.log(err);
    })
  }

  return (
    <div className="App">
      <div>
        <div className="file-upload">

          <h1>Выберите файл в формате json с данными телеграм чата</h1>
          <input type="file" onChange={handleChange} />

          {progress && 
            <div className="progessBar" style={{ width: progress }}>
              {progress}
            </div>
          }
          <button onClick={uploadFile} className="upbutton">
            Upload
          </button>

          <div>
            {data && (
              <>
                <div>Кол-во итераций: {data.chunks}</div>
                <div>Цена: {data.price} ₽</div>
                <div>Время выполнения: {data.timeMin} мин.</div>
              </>
            )}
            {error && (
              <div style={{color: 'red'}}>{error}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
